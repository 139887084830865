import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function ParentsInfoForm({
  tabs,
  updateTabComplete,
  handleFileChange,
  handleChangeInfo,
  handleFileRemove,
  parentsInfo,
  forView,
}) {
  let infoObject = "parents";
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;

  useEffect(() => {
    const keysToExclude = [
      // "maritalStatus",
      "fatherUploadNIC",
      "fatherUploadPassportPicture",
      "motherUploadNIC",
      "motherUploadPassportPicture",
    ];
    const isAnyKeyEmpty = Object.keys(parentsInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return (
          parentsInfo[key] === "" ||
          parentsInfo[key] === null ||
          parentsInfo[key].length == 0
        );
      });
    if (!isAnyKeyEmpty) {
      const isValidMobile =
        mobileRegex.test(parentsInfo.fatherMobileNumber) &
        mobileRegex.test(parentsInfo.motherMobileNumber);
      const isValidEmail =
        emailRegex.test(parentsInfo.fatherEmailAddress) &&
        emailRegex.test(parentsInfo.motherEmailAddress);
      const isValidNic =
        nicRegex.test(parentsInfo.fatherNICNumber) &&
        nicRegex.test(parentsInfo.motherNICNumber);
      if (isValidMobile && isValidEmail && isValidNic) {
        if (
          parentsInfo.fatherMobileNumber == parentsInfo.motherMobileNumber ||
          parentsInfo.motherEmailAddress == parentsInfo.fatherEmailAddress ||
          parentsInfo.fatherNICNumber == parentsInfo.motherNICNumber
        ) {
          updateTabComplete(false);
        } else {
          updateTabComplete(true);
        }
      } else {
        updateTabComplete(false);
      }
    } else {
      if (tabs[1].complete) {
        updateTabComplete(false);
      }
    }
  }, [parentsInfo]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6>Father's Detail</h6>
          <InputField
            label="Father's Name"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherName}
            placeholder="Type Name"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherName", e.target.value)
            }
          />
          <InputField
            label="Father's NIC Number"
            type="nic"
            compulsory={true}
            value={parentsInfo.fatherNICNumber}
            placeholder="Type NIC Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherNICNumber", e.target.value)
            }
          />
          <InputField
            label="Father's Occupation"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherOccupation}
            placeholder="Type Occupation"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherOccupation", e.target.value)
            }
          />
          <InputField
            label="Father's Organization"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherOrganization}
            placeholder="Type Organization"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherOrganization", e.target.value)
            }
          />
          <InputField
            label="Father's Education"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherEducation}
            placeholder="Type Education"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherEducation", e.target.value)
            }
          />
          <InputField
            label="Father's Mobile Number"
            type="tel"
            compulsory={true}
            value={parentsInfo.fatherMobileNumber}
            placeholder="Type Mobile Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherMobileNumber", e.target.value)
            }
          />
          <InputField
            label="Father's Email Address"
            type="email"
            compulsory={true}
            value={parentsInfo.fatherEmailAddress}
            placeholder="Type Email Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherEmailAddress", e.target.value)
            }
          />
          <InputImageField
            label="Upload Father's NIC"
            type="file"
            compulsory={false}
            value={parentsInfo.fatherUploadNIC}
            placeholder="Upload"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "fatherUploadNIC")
            }
            onChange={(e) => handleFileChange(infoObject, "fatherUploadNIC", e)}
          />
          <InputImageField
            label="Upload Father’s Passport Size Pic"
            type="file"
            compulsory={false}
            value={parentsInfo.fatherUploadPassportPicture}
            placeholder="Upload"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "fatherUploadPassportPicture")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "fatherUploadPassportPicture", e)
            }
          />
          <h6 className="mt-4">Mother's Details</h6>
          <InputField
            label="Mother's Name"
            type="text"
            compulsory={true}
            value={parentsInfo.motherName}
            placeholder="Type Name"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherName", e.target.value)
            }
          />
          <InputField
            label="Mother's NIC Number"
            type="nic"
            compulsory={true}
            value={parentsInfo.motherNICNumber}
            placeholder="Type NIC Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherNICNumber", e.target.value)
            }
          />
          <InputField
            label="Mother's Occupation"
            type="text"
            compulsory={true}
            value={parentsInfo.motherOccupation}
            placeholder="Type Occupation"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherOccupation", e.target.value)
            }
          />
          <InputField
            label="Mother's Organization"
            type="text"
            compulsory={true}
            value={parentsInfo.motherOrganization}
            placeholder="Type Organization"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherOrganization", e.target.value)
            }
          />
          <InputField
            label="Mother's Education"
            type="text"
            compulsory={true}
            value={parentsInfo.motherEducation}
            placeholder="Type Education"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherEducation", e.target.value)
            }
          />
          <InputField
            label="Mother's Mobile Number"
            type="tel"
            compulsory={true}
            value={parentsInfo.motherMobileNumber}
            placeholder="Type Mobile Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherMobileNumber", e.target.value)
            }
          />
          <InputField
            label="Mother's Email Address"
            type="email"
            compulsory={true}
            value={parentsInfo.motherEmailAddress}
            placeholder="Type Email Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherEmailAddress", e.target.value)
            }
          />
          <InputImageField
            label="Uplaod Mother's NIC"
            type="file"
            compulsory={false}
            value={parentsInfo.motherUploadNIC}
            placeholder="Upload"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "motherUploadNIC")
            }
            onChange={(e) => handleFileChange(infoObject, "motherUploadNIC", e)}
          />
          <InputImageField
            label="Upload Mother's Passport Size Pic"
            type="file"
            compulsory={false}
            value={parentsInfo.motherUploadPassportPicture}
            placeholder="upload"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "motherUploadPassportPicture")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "motherUploadPassportPicture", e)
            }
          />
          <SelectField
            label="Parent's Marital Status"
            compulsory={true}
            value={parentsInfo.maritalStatus}
            selectOptions={["Married", "Seperated", "Divorced"]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "maritalStatus", e.target.value)
            }
          />
        </div>
      </form>
    </React.Fragment>
  );
}
