import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCampusData } from "../../../api/Campus/CampusApiHelper";

export const CampusData = createAsyncThunk(
  "Campus/FetchCampusData",
  async () => {
    try {
      console.log("hitting campus api");
      const response = await fetchCampusData();
      return response; // Assuming response is an array of students
    } catch (error) {
      throw Error("Failed to fetch Campus data");
    }
  }
);

const initialState = {
  campus: [],
  currentCampus: 0,
  loading: false,
  error: null,
};

export const Campus = createSlice({
  name: "Campus",
  initialState,
  reducers: {
    currentCampusSelect: (state, action) => {
      console.log(action);
      localStorage.setItem("_schoolware_selected_campus_", action.payload);
      state.currentCampus = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(CampusData.pending, (state) => {
        state.loading = true;
      })
      .addCase(CampusData.fulfilled, (state, action) => {
        state.loading = false;
        console.log("campuses in slice", action.payload);
        state.campus = action.payload.items;
      })
      .addCase(CampusData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { currentCampusSelect } = Campus.actions;

export const allCampus = (state) => state.allCampus;

export default Campus.reducer;
