import React, { createContext, useState } from "react";
import { MdPersonOutline } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { CgCommunity } from "react-icons/cg";
import { MdOutlineEmergency } from "react-icons/md";
import { PiStudent } from "react-icons/pi";
import { MdSubject } from "react-icons/md";
import { MdOutlineMedicalServices } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
const SideBarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [activeStates, setActiveStates] = useState({
    main: "Personal",
    child: "",
  });
  let iconSize = 28;
  const [tabs, setTabs] = useState([
    {
      no: 1,
      tab: "Personal",
      icon: <MdPersonOutline size={iconSize} />,
      complete: false,
    },
    {
      no: 2,
      tab: "Parents",
      icon: <BsPeople size={iconSize} />,
      complete: false,
    },
    {
      no: 3,
      tab: "City School Association",
      icon: <CgCommunity size={iconSize} />,
      complete: false,
    },
    {
      no: 4,
      tab: "Emergency",
      icon: <MdOutlineEmergency size={iconSize} />,
      complete: false,
    },
    {
      no: 5,
      tab: "Education History",
      icon: <PiStudent size={iconSize} />,
      complete: false,
    },
    {
      no: 6,
      tab: "A-level Subjects",
      icon: <MdSubject size={iconSize} />,
      complete: false,
    },
    {
      no: 7,
      tab: "Medical",
      icon: <MdOutlineMedicalServices size={iconSize} />,
      complete: false,
    },
    {
      no: 8,
      tab: "Confirm",
      icon: <GrStatusGood size={iconSize} />,
      complete: false,
    },
    // { no: 7, tab: "Confirm", complete: false },
  ]);

  return (
    <SideBarContext.Provider
      value={{ activeStates, setActiveStates, tabs, setTabs }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
