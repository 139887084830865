import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allClasses } from "../../../../redux/slice/academics/classesSlice";
import { IoMdAdd } from "react-icons/io";
import { subjectsOffered } from "../../../../db/fields-data";

export default function ALevelSubject({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  AlevelSubjectInfo,
  forView,
}) {
  let infoObject = "ALevelSubject";
  const { classes } = useSelector(allClasses);
  const classNames = [...new Set(classes.map((cls) => cls.name))];
  const [addedNoOfSubjects, setAddedNoOfSubjects] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState([
    { subject: "" },
  ]);
  useEffect(() => {
    const filterValue = subjectsAppearedDetails.filter((v) => v.subject === "");
    if (subjectsAppearedDetails.length - filterValue.length >= 3) {
      updateTabComplete(true);
      // console.log("checked length", subjectsAppearedDetails.length);
    } else {
      // console.log("checked length false", subjectsAppearedDetails.length);
      updateTabComplete(false);
    }
  }, [AlevelSubjectInfo]);

  const handleAddSubject = () => {
    if (subjectsAppearedDetails.length >= 5) {
      setWarningMessage("Limit exceeded: You can only add up to 5 subjects.");
    } else if (
      subjectsAppearedDetails.length === 0 ||
      subjectsAppearedDetails[subjectsAppearedDetails.length - 1].subject !== ""
    ) {
      setSubjectsAppearedDetails([...subjectsAppearedDetails, { subject: "" }]);
      setWarningMessage(""); // Clear the warning message if a new subject is successfully added
    } else {
      setWarningMessage("Please select a subject before adding a new one.");
    }
  };
  const getFilteredOptions = (currentIndex) => {
    const selectedOptions = subjectsAppearedDetails
      .slice(0, currentIndex)
      .map((subject) => subject.subject);
    return subjectsOffered.filter(
      (option) => !selectedOptions.includes(option)
    );
  };
  // console.log("subject name", subjectsAppearedDetails);


  const handleSubjectChange = (index, key, value) => {
    const updatedSubjects = subjectsAppearedDetails.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setSubjectsAppearedDetails(updatedSubjects);
    handleChangeInfo(infoObject, "subjectsTaken", updatedSubjects);
  };

  const handleRemoveLastRow = () => {
    if (subjectsAppearedDetails.length > 1) {
      const updatedSub = subjectsAppearedDetails.slice(0, -1);
      setSubjectsAppearedDetails(updatedSub);
      handleChangeInfo(infoObject, "subjectsTaken", updatedSub);
    } else {
      // Notify("error", "At least three subject are required");
    }
  };

  return (
    <React.Fragment>
      <form>
        <h6>Select A Level Subjects</h6>
        <p>
          Please select minimum of 3 A Level subjects. You can select up to 5 A
          Level subjects
        </p>
        <div className="row">
          {warningMessage && <p style={{ color: "red" }}>{warningMessage}</p>}
          {subjectsAppearedDetails.map((subject, index) => (
            <div className="row" key={index}>
              <SelectField
                label={`Subject ${index + 1}`}
                compulsory={true}
                value={subject.subject}
                selectOptions={getFilteredOptions(index)}
                disabled={forView}
                onChange={(e) =>
                  handleSubjectChange(index, "subject", e.target.value)
                }
              />
            </div>
          ))}
        </div>
        <div className=" mt-2">
          <button
            type="button"
            disabled={subjectsAppearedDetails.length > 4}
            className="btn btn-success"
            onClick={handleAddSubject}
          >
            Add Subject
          </button>
          {subjectsAppearedDetails.length > 3 ? (
            <button
              type="button"
              className="btn btn-danger mx-1"
              onClick={handleRemoveLastRow}
            >
              Remove Last Row
            </button>
          ) : null}
        </div>
      </form>
    </React.Fragment>
  );
}
