import React, { useRef, useState } from "react";
import Select from "react-select";
import { MdDelete } from "react-icons/md";

const InputField = ({
  label,
  compulsory,
  type,
  className,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  let regex;
  let defaultValue = "";
  let errorMessage = "";
  if (type === "email") {
    // email validation regex if type = email
    regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    errorMessage = "This is not a valid email address.";
  } else if (type === "nic") {
    // cnic validation regex if type = nic
    regex = /^[0-9]{5}[0-9]{7}[0-9]$/;
    type = "text";
    errorMessage = "NIC number should contain 13 digits.";
  } else if (type === "tel") {
    // phone validation regex if type = tel
    regex = /^\+92\d{10}$/;
    defaultValue = "+92";
    errorMessage =
      "Number should start with +92 and contain 10 digits onwards.";
  } else {
    errorMessage = "";
  }

  const isValid = regex && value != defaultValue ? regex.test(value) : true;

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      {/* <form> */}
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <input
        className={`form-control text-black ${!isValid ? "is-invalid" : ""}`}
        type={type}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required
      />
      {!isValid && !disabled && <p className="text-danger">{errorMessage}</p>}
      {/* </form> */}
    </div>
  );
};

const InputImageField = ({
  label,
  compulsory,
  type,
  className,
  value,
  placeholder,
  disabled,
  handleFileRemove,
  onChange,
}) => {
  const inputRef = useRef(null);
  let imageUrl = null;
  let fieldName = "";
  if (value && value[0] instanceof Blob) {
    let filterValue = value.filter((item) => item != undefined);
    imageUrl = URL.createObjectURL(filterValue[filterValue.length - 1]);
    fieldName = value[filterValue.length - 1].name;
    if (fieldName.length > 18) {
      fieldName = fieldName.substring(0, 18) + "...";
    }
  } else {
    imageUrl = null;
    fieldName = "No file Chosen";
  }

  const handleRemoveFile = () => {
    handleFileRemove();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const openFileDialog = () => {
    document.getElementById(label).click();
  };
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      {/* <form> */}
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <div className="input-image-container" onClick={openFileDialog}>
        <div className="input-image-browse">Browse</div>
        <div className="input-image-name text-black poppins-regular">
          {fieldName}
        </div>
      </div>
      <input
        className={`form-control text-black d-none`}
        id={label}
        type="file"
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        required
        label={fieldName}
        ref={inputRef}
      />
      {imageUrl && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-1">
          <img
            className="img-thumbnail"
            style={{ width: "35%", objectFit: "cover", textAlign: "center" }}
            src={imageUrl}
            alt="Uploaded File"
          />
          <button
            onClick={handleRemoveFile}
            className="btn btn-danger"
            style={{ width: "35%" }}
          >
            <MdDelete size={20} />
          </button>
        </div>
      )}
      {/* </form> */}
    </div>
  );
};

const SelectField = ({
  label,
  compulsory,
  className,
  value,
  selectOptions,
  disabled,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <select
        className="form-select text-black"
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {selectOptions.map((option, index) => (
          <option key={index} option={option}>
            {option.toString()}
          </option>
        ))}
      </select>
    </div>
  );
};

const SelectFieldForId = ({
  label,
  compulsory,
  className,
  value,
  selectOptions,
  disabled,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <select
        className="form-select text-black"
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {selectOptions.map((option, index) => (
          <option key={index} option={option} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const SelectFieldTeacher = ({
  label,
  compulsory,
  value,
  selectOptions,
  onChange,
}) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 my-2">
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <select
        className="form-select text-black"
        value={value}
        onChange={onChange}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {selectOptions.map((option, index) => (
          <option
            key={index}
            value={option.personalInfoFormData.name}
            option={option.personalInfoFormData.name}
          >
            {option.personalInfoFormData.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const InputNumber = ({
  label,
  compulsory,
  type,
  className,
  value,
  step,
  min,
  max,
  disabled,
  placeholder,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <input
        className="form-control text-black"
        step={step}
        min={min}
        max={max}
        disabled={disabled}
        type="number"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export {
  InputField,
  SelectField,
  SelectFieldForId,
  InputNumber,
  SelectFieldTeacher,
  InputImageField,
};
