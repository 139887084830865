import React, { useEffect, useState } from "react";
import { InputField, SelectField } from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function EmergencyContactForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  emergencyContact,
  forView,
}) {
  let infoObject = "emergency";
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;

  useEffect(() => {
    const isAnyKeyEmpty = Object.values(emergencyContact).some((value) => {
      return value === "" || value === null;
    });
    if (!isAnyKeyEmpty) {
      const isValidMobile = mobileRegex.test(emergencyContact.mobileNumber);
      const isValidEmail = emailRegex.test(emergencyContact.email);
      if (isValidMobile && isValidEmail) {
        updateTabComplete(true);
      } else {
        updateTabComplete(false);
      }
    } else {
      if (tabs[3].complete) {
        updateTabComplete(false);
      }
    }
  }, [emergencyContact]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6>Emergency Contact Information</h6>
          <InputField
            label="Name"
            type="text"
            compulsory={true}
            value={emergencyContact.name}
            placeholder="Name"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "name", e.target.value)
            }
          />
          <InputField
            label="Relationship with Child"
            type="text"
            compulsory={true}
            value={emergencyContact.relWithChild}
            placeholder="Enter Relation With Child"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "relWithChild", e.target.value)
            }
          />
          <InputField
            label="Mobile Number"
            type="tel"
            compulsory={true}
            value={emergencyContact.mobileNumber}
            placeholder="Mobile Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "mobileNumber", e.target.value)
            }
          />
          <InputField
            label="Email"
            type="email"
            compulsory={true}
            value={emergencyContact.email}
            placeholder="Email"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "email", e.target.value)
            }
          />
          <InputField
            label="Home Address"
            type="text"
            compulsory={true}
            value={emergencyContact.homeAddress}
            placeholder="Home Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "homeAddress", e.target.value)
            }
          />
        </div>
      </form>
    </React.Fragment>
  );
}
