import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginData, LoginGoogle } from "../../../api/Authentication/LoginhelperApi";

export const LoginUser = createAsyncThunk("login", async (data) => {
  try {
    const response = await LoginData(data);
    return response;
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return error.code;
    }
    return error.response;
  }
});

export const LoginWithGoogle = createAsyncThunk("login/google", async (data) => {
  try {
    localStorage.setItem("sw_google_token", data)
    const response = await LoginGoogle(data);
    return response;
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return error.code;
    }
    return error.response;
  }
});

const initialState = {
  response: [],
  loading: false,
  error: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      return {
        ...state,
        response: [],
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous error on pending
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("data",action.payload.token)
        localStorage.setItem("username_data",action.payload.username)
        state.response = action.payload;
        state.error = null;
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message in state
      });
    builder
      .addCase(LoginWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous error on pending
      })
      .addCase(LoginWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("data",action.payload.token)
        localStorage.setItem("username_data",action.payload.username)
        state.response = action.payload;
        state.error = null;
      })
      .addCase(LoginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error message in state
      });
  },
});

export const { logoutUser } = loginSlice.actions;
export const allLogin = (state) => state.allLogin;
export default loginSlice.reducer;
