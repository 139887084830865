// axiosConfig.js
import axios from "axios";
const baseURL =
  window.location.hostname === "tcsadmission.schoolware.net" ||
  window.location.hostname === "cs-admission.netlify.app"
    ? process.env.REACT_APP_API_URL_STAGING
    : process.env.REACT_APP_API_URL_DEVELOPMENT;

console.log("baseURL -> ", baseURL);

const axiosInstance = axios.create({
  baseURL: baseURL,
  // baseURL: "https://staging.schoolware.net/api/",
  // baseURL: "http://25.16.188.189:5000/api/",
});

let token = localStorage.getItem("data");

const instanceAuth = axios.create({
  baseURL: baseURL,
  // baseURL: "http://25.16.188.189:5000/api/",
  // baseURL: "https://staging.schoolware.net/api/",
  headers: {
    authorization: "Bearer " + token,
  },
});
export { axiosInstance, instanceAuth };
