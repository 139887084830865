import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardTitle, Col, Row, Table, Button } from "reactstrap";
import { MdModeEdit } from "react-icons/md";
import NewApplicants from "./new-applicants";
import TableComponent from "../../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { CampusData } from "../../../redux/slice/academics/CampusSlice";
import { FaLock } from "react-icons/fa";

export default function AdmissionForm() {
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const [forView, setForView] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [loader, setLoader] = useState(true);

  const createToggle = () => {
    setSelectedStudent({});
    setCreate(!create);
  };

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoader(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, []);

  useEffect(() => {
    dispatch(CampusData());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Breadcrumbs title="Admissions" breadcrumbItem="New Application" /> */}
        <NewApplicants />
      </div>
    </React.Fragment>
  );
}
