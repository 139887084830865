import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [
    {
      personal: {
        studentId: "SID#7777",
        name: "John Doe",
        dateOfBirth: "2005-05-15",
        bForm: "12345-6789012-3",
        nationality: "American",
        gender: "Male",
        permanentAddress: "123 Main St",
        residentialAddress: "456 Elm St",
        city: "New York",
        area: "Manhattan",
        mobileNumber: "123-456-7890",
        emailAddress: "john.doe@example.com",
        passportSizePicture: ["passport1.jpg"],
        birthCertificate: ["birth_certificate.pdf"],
        b_form: ["b_form.pdf"],
      },
      parents: {
        fatherName: "Jack Doe",
        fatherNICNumber: "12345-6789012-4",
        fatherOccupation: "Engineer",
        fatherOrganization: "ABC Corp",
        fatherEducation: "Bachelor",
        fatherMobileNumber: "111-222-3333",
        fatherEmailAddress: "jack.doe@example.com",
        fatherUploadNIC: ["father_nic.jpg"],
        fatherUploadPassportPicture: ["father_passport.jpg"],
        motherName: "Jill Doe",
        motherNICNumber: "12345-6789012-5",
        motherOccupation: "Doctor",
        motherOrganization: "XYZ Hospital",
        motherEducation: "Doctorate",
        motherMobileNumber: "444-555-6666",
        motherEmailAddress: "jill.doe@example.com",
        motherUploadNIC: ["mother_nic.jpg"],
        motherUploadPassportPicture: ["mother_passport.jpg"],
        maritalStatus: "Married",
      },
      emergency: {
        name: "Emergency Contact",
        relWithChild: "Parent",
        mobileNumber: "777-888-9999",
        email: "emergency.contact@example.com",
        homeAddress: "789 Oak St",
      },
      education: {
        previouslyAttended: "Yes",
        schoolName: "ABC School",
        schoolAddress: "789 Maple St",
        class: "",
        lastAcademicResults: "A+",
        reasonForLeaving: "Graduated",
        term: "Fall 2023",
        campus: "Main Campus",
      },
      medical: {
        diabetes: false,
        physicalDisability: true,
        hearingImpairment: false,
        visualImpairment: false,
        anyAllergies: true,
        allergies: "Peanuts",
      },
      status: {
        status: "Active",
      },
    },
    // {
    //   personal: {
    //     studentId: "SID#5884",
    //     name: "John Doe",
    //     dateOfBirth: "2005-05-15",
    //     bForm: "12345-6789012-3",
    //     nationality: "American",
    //     gender: "Male",
    //     permanentAddress: "123 Main St",
    //     residentialAddress: "456 Elm St",
    //     city: "New York",
    //     area: "Manhattan",
    //     mobileNumber: "123-456-7890",
    //     emailAddress: "john.doe@example.com",
    //     passportSizePicture: ["passport1.jpg"],
    //     birthCertificate: ["birth_certificate.pdf"],
    //     b_form: ["b_form.pdf"],
    //   },
    //   parents: {
    //     fatherName: "Jack Doe",
    //     fatherNICNumber: "12345-6789012-4",
    //     fatherOccupation: "Engineer",
    //     fatherOrganization: "ABC Corp",
    //     fatherEducation: "Bachelor",
    //     fatherMobileNumber: "111-222-3333",
    //     fatherEmailAddress: "jack.doe@example.com",
    //     fatherUploadNIC: ["father_nic.jpg"],
    //     fatherUploadPassportPicture: ["father_passport.jpg"],
    //     motherName: "Jill Doe",
    //     motherNICNumber: "12345-6789012-5",
    //     motherOccupation: "Doctor",
    //     motherOrganization: "XYZ Hospital",
    //     motherEducation: "Doctorate",
    //     motherMobileNumber: "444-555-6666",
    //     motherEmailAddress: "jill.doe@example.com",
    //     motherUploadNIC: ["mother_nic.jpg"],
    //     motherUploadPassportPicture: ["mother_passport.jpg"],
    //     maritalStatus: "Married",
    //   },
    //   emergency: {
    //     name: "Emergency Contact",
    //     relWithChild: "Parent",
    //     mobileNumber: "777-888-9999",
    //     email: "emergency.contact@example.com",
    //     homeAddress: "789 Oak St",
    //   },
    //   education: {
    //     previouslyAttended: "Yes",
    //     schoolName: "ABC School",
    //     schoolAddress: "789 Maple St",
    //     class: "10",
    //     lastAcademicResults: "A+",
    //     reasonForLeaving: "Graduated",
    //     term: "Fall 2023",
    //     campus: "Main Campus",
    //   },
    //   medical: {
    //     diabetes: false,
    //     physicalDisability: true,
    //     hearingImpairment: false,
    //     visualImpairment: false,
    //     anyAllergies: true,
    //     allergies: "Peanuts",
    //   },
    //   status: {
    //     status: "Active",
    //   },
    // },
  ],
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    addStudent: (state, action) => {
      // add working
      console.log(action);
      state.students.push(action.payload);
    },
    updateStudent: (state, action) => {
      // Update an existing student based on studentId
      console.log("update data payload", action.payload);
      const updatedStudent = action.payload;
      const index = state.students.findIndex(
        (student) =>
          student.personal.studentId === updatedStudent.personal.studentId
      );
      if (index !== -1) {
        state.students[index] = updatedStudent;
      } else {
        console.error("Student not found for update");
      }
    },
    removeStudent: (state, action) => {
      return {
        ...state,
        students: state.students.filter((i) => i.personal.studentId !== action.payload),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addStudent, updateStudent, removeStudent } = studentSlice.actions;

export const allStudents = (state) => state.allStudents;

export default studentSlice.reducer;
