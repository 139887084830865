import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function PersonalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  personalInfo,
  forView,
}) {
  let infoObject = "personal";
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;

  useEffect(() => {
    const keysToExclude = [
      "mobileNumber",
      "emailAddress",
      "birthCertificate",
      "b_form",
    ];
    const isAnyKeyEmpty = Object.keys(personalInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return (
          personalInfo[key] === "" ||
          personalInfo[key] === null ||
          personalInfo[key].length == 0
        );
      });
    // console.log("is any key empty", isAnyKeyEmpty);
    if (!isAnyKeyEmpty) {
      const isValidMobile = mobileRegex.test(personalInfo.mobileNumber);
      const isValidEmail = emailRegex.test(personalInfo.emailAddress);
      const isValidNic = nicRegex.test(personalInfo.bForm);
      if (isValidMobile && isValidEmail && isValidNic) {
        updateTabComplete(true);
      } else {
        updateTabComplete(false);
      }
    } else {
      if (tabs[0].complete) {
        // console.log("tabs", tabs);
        updateTabComplete(false);
      }
    }
  }, [personalInfo]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6>Personal Information</h6>
          <InputField
            label="Name"
            compulsory={true}
            type="text"
            value={personalInfo.name}
            placeholder="Type Name"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "name", e.target.value)
            }
          />
          <InputField
            label="Date of Birth"
            compulsory={true}
            type="date"
            value={personalInfo.dateOfBirth}
            placeholder="Type Date of Birth"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "dateOfBirth", e.target.value)
            }
          />
          <InputField
            label="B Form / CNIC"
            compulsory={true}
            type="nic"
            value={personalInfo.bForm}
            placeholder="Type B Form"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "bForm", e.target.value)
            }
          />
          <SelectField
            label="Nationality"
            compulsory={true}
            value={personalInfo.nationality}
            selectOptions={["Pakistani", "American", "British"]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "nationality", e.target.value)
            }
          />
          <SelectField
            label="Gender"
            compulsory={true}
            value={personalInfo.gender}
            selectOptions={["Male", "Female"]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "gender", e.target.value)
            }
          />
          <InputField
            label="Permanent Address"
            compulsory={true}
            type="text"
            value={personalInfo.permanentAddress}
            placeholder="Type Permanent Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "permanentAddress", e.target.value)
            }
          />
          <InputField
            label="Residential Address"
            compulsory={true}
            type="text"
            value={personalInfo.residentialAddress}
            placeholder="Type Residential Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "residentialAddress", e.target.value)
            }
          />
          <InputField
            label="City"
            compulsory={true}
            value={personalInfo.city}
            placeholder="Type City"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "city", e.target.value)
            }
          />
          <InputField
            label="Area"
            compulsory={true}
            value={personalInfo.area}
            placeholder="Type Area"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "area", e.target.value)
            }
          />
          <InputField
            label="Mobile Number"
            compulsory={false}
            type="tel"
            value={personalInfo.mobileNumber}
            placeholder="Type Mobile Number"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "mobileNumber", e.target.value)
            }
          />
          <InputField
            label="Email Address"
            compulsory={false}
            type="email"
            value={personalInfo.emailAddress}
            placeholder="Type Email Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "emailAddress", e.target.value)
            }
          />
          <InputImageField
            label="Passport Size Picture"
            compulsory={true}
            value={personalInfo.passportSizePicture}
            type="file"
            placeholder="Upload Passport Size Picture"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "passportSizePicture")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "passportSizePicture", e)
            }
          />
          <InputImageField
            label="Birth Certificate"
            compulsory={false}
            value={personalInfo.birthCertificate}
            type="file"
            placeholder="Upload Birth Certificate"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "birthCertificate")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "birthCertificate", e)
            }
          />
          <InputImageField
            label="B-Form"
            compulsory={false}
            value={personalInfo.b_form}
            type="file"
            placeholder="Upload B-Form"
            disabled={forView}
            handleFileRemove={() => handleFileRemove(infoObject, "b_form")}
            onChange={(e) => handleFileChange(infoObject, "b_form", e)}
          />
        </div>
      </form>
    </React.Fragment>
  );
}
