import { toast } from "react-toastify";
import { GoIssueClosed } from "react-icons/go";
import { MdErrorOutline } from "react-icons/md";

export default function Notify(status, message) {
  if (status === "success") {
    toast.success(message, {
      icon: <GoIssueClosed size={16} />,
      position: "top-center",
      style: {
        background: "#64D45B",
        color: "white",
        fontSize: "15px",
      },
    });
  } else {
    toast.error(message, {
      icon: <MdErrorOutline size={16} />,
      position: "top-center",
      style: {
        background: "#E67063",
        color: "white",
        fontSize: "15px",
      },
    });
  }
}
