import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { logoutUser } from "../../../redux/slice/authentication/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// users
import user4 from "../../../assets/images/city_school_profile.png";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
    let user_name = localStorage.getItem("username_data");
    console.log("localUser Profile", user_name);
    let u_name = "";
    if (user_name) {
      if(user_name.includes("@")){
        u_name = user_name?.split("@")[0];
      }
      else{
        u_name = user_name
      }
      if(u_name.length < 11){
        setusername(u_name);
      }
      else{
        setusername(u_name.substring(0, 7) + "...")
      }
    }
  }, []);

  const logout = () => {
    console.log("logging out...");
    localStorage.removeItem("data");
    localStorage.removeItem("username_data");
    dispatch(logoutUser());
    navigate("/logout");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1" style={{color: "#08539F"}}>
            {username}
          </span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" style={{marginLeft: "-80px"}}>
          {/* <DropdownItem tag="a" href="" disabled>
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1"></i> View
            Profile
          </DropdownItem>
          <DropdownItem tag="a" href="/#" disabled>
            <i className="bx bx-wallet font-size-16 align-middle me-1"></i> My
            Wallet
          </DropdownItem>
          <DropdownItem tag="a" href="#" disabled>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{' '}
            Settings
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen" disabled>
            <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>{' '}
            Lock screen
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <a onClick={logout} className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span style={{ right: "20px" }}>Logout</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
