import React, { useContext, useEffect, useState } from "react";
import Header from "./header.js";
import Sidebar from "./sidebar.js";
import Rightbar from "../CommonForBoth/Rightbar";
import { useLocation } from "react-router-dom";
import Footer from "./footer.js";
import "./workingLayout.scss";
import SideBarContext from "../../context/sidebar.context.js";
import logoGif from "../../assets/images/city-school-gif-transparent.gif";
import citySchool from "../../assets/images/logo/the-city-school-logo.png";

// constructor(props) {
//   super(props)
//   this.state = {
//     isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
//   }
//   this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
// }

// capitalizeFirstLetter = string => {
//   return string.charAt(1).toUpperCase() + string.slice(2)
// }

// componentDidMount() {
//   if (this.props.isPreloader === true) {
//     document.getElementById("preloader").style.display = "block"
//     document.getElementById("status").style.display = "block"

//     setTimeout(function () {
//       document.getElementById("preloader").style.display = "none"
//       document.getElementById("status").style.display = "none"
//     }, 2500)
//   } else {
//     document.getElementById("preloader").style.display = "none"
//     document.getElementById("status").style.display = "none"
//   }

//   // Scroll Top to 0
//   window.scrollTo(0, 0)
//   let currentage = this.capitalizeFirstLetter(this.props.router.location.pathname)

//   document.title =
//     currentage + " | Braincrop - Schoolware 🏫"
//   if (this.props.leftSideBarTheme) {
//     this.props.changeSidebarTheme(this.props.leftSideBarTheme)
//   }

//   this.props.changeLayout("detached")
//   // this.props.changeLayoutTheme("dark")
//   if (this.props.theme) {
//     this.props.changeLayoutTheme(this.props.theme)
//   }
//   if (this.props.layoutWidth) {
//     this.props.changeLayoutWidth(this.props.layoutWidth)
//   }

//   if (this.props.leftSideBarType) {
//     this.props.changeSidebarType(this.props.leftSideBarType)
//   }
//   if (this.props.topbarTheme) {
//     this.props.changeTopbarTheme(this.props.topbarTheme)
//   }
// }

// toggleMenuCallback = () => {
//   if (this.props.leftSideBarType === "default") {
//     this.props.changeSidebarType("condensed", this.state.isMobile)
//   } else if (this.props.leftSideBarType === "condensed") {
//     this.props.changeSidebarType("default", this.state.isMobile)
//   }
// }

// render() {

function Layout(props) {
  const { setActiveStates } = useContext(SideBarContext);
  const location = useLocation();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoader(false);
    }, 3000);
    return () => clearTimeout(loaderTimeout);
  }, []);

  // useEffect to set pathnames on reload
  useEffect(() => {
    let _pathArr = location.pathname.split("/");
    let _main = _pathArr[1].charAt(0).toUpperCase() + _pathArr[1].slice(1);
    setActiveStates({
      main: _main,
      child:
        _pathArr[2] !== undefined
          ? _pathArr[2].charAt(0).toUpperCase() + _pathArr[2].slice(1)
          : "",
    });
  }, []);

  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div> */}
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{  width:"100vw", height: "95vh", objectFit:"cover" }}
        >
          {/* <span className="loader"></span> */}
          <img src={logoGif} style={{width: "65%", height: "65%", objectFit: "contain", background:"transparent"}} />
          {/* <img className="zoom-image" src={citySchool} style={{width: "65%", height: "65%", objectFit: "contain", background:"transparent"}} /> */}
        </div>
      ) : (
        <div className="container-fluid-working">
          <div className="layout-wrapper">
            <Header />
            {/* <Sidebar /> */}
            <div className="main-content-working">
              {props.children}
              <Footer />
            </div>
          </div>
        </div>
      )}
      {/* {this.props.showRightSidebar ? <Rightbar /> : null} */}
    </React.Fragment>
  );
}
// }

export default Layout;
