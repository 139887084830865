import { axiosInstance } from '../axiosConfig';

export const emailVerifiaction = async (data) => {
  try {
    const response = await axiosInstance.post('Auth/UserRegister', data);
    return response;
  } catch (error) {
    console.log('Error in email verification', error);
    throw error;    
  }
};
export const otpVerification = async (data) => {
  try {
    const response = await axiosInstance.post('Auth/VerifyOTP', data);
    return response;
  } catch (error) {
    console.log('Error in otp verification', error);
    throw error;    
  }
};
export const RegisterData = async (data) => {
  try {
    const response = await axiosInstance.post('Auth/SetPassword', data);
    return response;
  } catch (error) {
    console.log('error in registration', error);
    throw error;    
  }
};
