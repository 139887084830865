import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from "../../../redux/slice/academics/CampusSlice";
import {
  allClasses,
  ClassesData,
} from "../../../redux/slice/academics/classesSlice";
import {
  InputField,
  SelectField,
  SelectFieldForId,
  InputImageField,
} from "../../../components/input-fields";
import { Link } from "react-router-dom";
import Loader from "../../../components/loader/Loader";

export default function StatupForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  educationInfo,
  forView,
}) {
  let infoObject = "education";
  const dispatch = useDispatch();
  const { campus, currentCampus, loading } = useSelector(allCampus);
  const { classes } = useSelector(allClasses);
  const clsRedux = useSelector(allClasses);
  const [campusOptions, setCampusOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);

  useEffect(() => {
    console.log("campus", campus);
    const transformedCampusData = campus.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    console.log("transformedCampusData", transformedCampusData);
    setCampusOptions(transformedCampusData);
    if (educationInfo?.campus) {
      console.log("education campus", educationInfo.campus);
      dispatch(currentCampusSelect(educationInfo.campus));
    }
  }, [campus, educationInfo.campus]);

  useEffect(() => {
    if (educationInfo.campus != "") {
      dispatch(ClassesData(Number(educationInfo.campus)));
    }
  }, [educationInfo.campus]);

  useEffect(() => {
    console.log("classes", classes);
    const transformedClassData = classes.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    console.log("transformedClassData", transformedClassData);
    setClassOptions(transformedClassData);
  }, [classes]);

  return (
    <React.Fragment>
      <form>
        {loading ? (
          <div className="row m-4">
            <Loader />
          </div>
        ) : campus.length == 0 ? (
          <p className="text-center fs-4">No campus found</p>
        ) : (
          <div className="row">
            <SelectFieldForId
              label="Campus"
              compulsory={true}
              value={educationInfo.campus}
              selectOptions={campusOptions}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo("education", "campus", e.target.value)
              }
            />
            <SelectFieldForId
              label="Class"
              compulsory={true}
              value={educationInfo.class}
              selectOptions={classOptions}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo("education", "class", e.target.value)
              }
            />
            <SelectField
              label="Academic Year"
              compulsory={true}
              value={educationInfo.term}
              selectOptions={["2024-25"]}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo("education", "term", e.target.value)
              }
            />
            {!clsRedux.loading ? (
              <p className="text-center text-danger">
                {classes.length == 0 &&
                campus.length != 0 &&
                educationInfo.campus != ""
                  ? "This campus has no class."
                  : ""}
              </p>
            ) : null}
          </div>
        )}
      </form>
    </React.Fragment>
  );
}
