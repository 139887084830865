import { combineReducers, configureStore } from "@reduxjs/toolkit";
import studentSlice from "../slice/students/studentsSlice";
import allCampus from '../slice/academics/CampusSlice';
import classSlice from "../slice/academics/classesSlice";
import Registerslice from '../slice/authentication/registerSlice';
import allLogin from '../slice/authentication/loginSlice';
import allTerms from "../slice/academics/termSlice";

const rootReducer = combineReducers({
  allStudents: studentSlice,
  allClasses: classSlice,
  allCampus: allCampus,
  allTerms: allTerms,
  allRegistration: Registerslice,
  allLogin: allLogin,
});

export const store = configureStore({
  reducer: rootReducer,
  // middleware: getDefalutMiddleware =>
  //   getDefalutMiddleware({
  //     serializableCheck: false,
  //   }).concat(logger),
});
