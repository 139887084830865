import { axiosInstance, instanceAuth } from '../axiosConfig';

export const fetchClassData = async (data) => {
  try {
    console.log("campus id on class", data)
    const response = await instanceAuth.get(`Class?CampusId=${data}`);
    return response.data;
  } catch (error) {
    console.log('error fetching class data...', error);
    throw error;
  }
};