import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import images
import Companylogo from "../../assets/images/logo/The_City_School_(Pakistan)_Logo_2.png";
import ProfileMenu from "../commonWorkingComponents/TopbarDropdown/ProfileMenu";
import "../../App.css";
import CampusDropdown from "./campusDropdown";

const Header = (props) => {
  const [search, setsearch] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.bkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 768) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container
            fluid
            className="d-flex flex-row-reverse justify-content-lg-between align-items-center"
          >
            {/* <div className="float-end"> */}
            {/* <Dropdown
                className="d-inline-block d-lg-none ms-2"
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
              >
                <DropdownToggle
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  tag="button"
                >
                  {' '}
                  <i className="mdi mdi-magnify"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="m-0">
                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control text-white"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <Button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown> */}
            {/* <LanguageDropdown />{" "} */}
            {/* <Dropdown className="d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen  text-white"></i>
                </button>
              </Dropdown>{' '} */}
            {/* <NotificationDropdown /> 
              <ProfileMenu />{' '}
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
                onClick={() => {
                  //   props.showRightSidebarAction(!props.showRightSidebar);
                }}
              >
                <i className="mdi mdi-settings-outline"></i>
              </button> */}
            {/* </div> */}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "baseline",
                justifyContent: "space-between",
                // marginInline: "4%",
              }}
            >
              {/* style={{display: "flex", alignItems: "center", justifyContent:"center"}} */}
              {/* style={{ width: "200px"}} */}
              <div className="logo-container">
                <Link to="/" className="logo logo-dark">
                  <img
                    src={Companylogo}
                    // style={{height: "95px", marginTop: "-15px", marginLeft: "-12px", borderTopRightRadius: "55px", borderBottomRightRadius: "55px"}}
                    style={{
                      height: "95px",
                      width: "280px",
                      marginTop: "-10px",
                    }}
                    alt="company-logo"
                    className="text-center bg-white"
                    // width={200}
                    // height={100}
                  />
                </Link>

                <Link to="/" className="logo logo-light">
                  <img
                    src={Companylogo}
                    alt="company-logo"
                    width={180}
                    height={60}
                  />
                </Link>
                <div
                  style={{
                    position: "absolute",
                    width: "200px",
                    left: "80px",
                    bottom: "-2px",
                  }}
                >
                  <CampusDropdown />
                </div>
              </div>
              <div
                className="d-flex"
                style={{ position: "absolute", right: "0", top: "5px" }}
              >
                <Dropdown
                  className="d-none d-lg-inline-block ms-1"
                  // style={{ position: "absolute", left: "90%" }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      toggleFullscreen();
                    }}
                    className="btn header-item noti-icon waves-effect"
                    data-toggle="fullscreen"
                  >
                    <i
                      className="mdi mdi-fullscreen"
                      style={{ color: "#08539F" }}
                    ></i>
                  </button>
                </Dropdown>{" "}
                <ProfileMenu />{" "}
              </div>
              {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  tToggle();
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button> */}
              {/* <Form className="app-search d-none d-lg-inline-block w-100 mt-3">
                <div className="position-relative">
                  <input
                    type="text"
                    style={{ outline: 'none' }}
                    className="w-100 rounded-2 header-search bg-transparent py-2 px-5 text-white"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt text-white"></span>
                </div>
              </Form>{' '} */}
            </div>
          </Container>
        </div>
        {/* <div style={{height: "3px", width: "auto", marginTop:"0px", backgroundColor: "#08539F"}}></div> */}
      </header>
    </>
  );
};

export default Header;
