import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store } from "./redux/store/index.js";
import { SidebarProvider } from "./context/sidebar.context.js";
// import store from './store';

let GL_Client_ID = process.env.REACT_APP_Google_Login_Client_ID;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={GL_Client_ID}>
  <SidebarProvider>
    <Provider store={store}>
      {/* <Provider store={store}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </SidebarProvider>
  </GoogleOAuthProvider>
);

serviceWorker.unregister();
