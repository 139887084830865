import { axiosInstance } from '../axiosConfig';

const parseJwt = async (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};


export const LoginData = async (data) => {
  let userData = {
    status: 500,
    username: "",
    role: "",
    token: "",
  };
  try {
    const response = await axiosInstance.post('/Auth/Login', data);
    console.log("get response", response)
    if(response.status == 200){
      let respToken = response.data.jwtToken
      const decodedToken = await parseJwt(respToken);
      if (decodedToken) {
          userData.status = response.status;
          userData.username = decodedToken['Username'];
          userData.role = decodedToken['Role'];
          userData.token = respToken
      }
    }
    else{
      return response
    }
    return userData;
  } catch (error) {
    console.log('error-for-login', error);
    throw error;
  }
};

export const LoginGoogle = async (data) => {
  let userData = {
    status: 500,
    username: "",
    role: "",
    token: "",
  };
  try {
    const response = await axiosInstance.post('/Auth/GoogleLogin', {tokenId: data});
    if(response){
      let respToken = response.data.jwtToken
      const decodedToken = await parseJwt(respToken);
      // console.log("response gl", decodedToken)
      if (decodedToken) {
          userData.status = response.status;
          userData.username = decodedToken?.Username;
          userData.role = decodedToken.Role;
          userData.token = respToken
      }
    }
    return userData;
  } catch (error) {
    console.log('error-for-google-login', error);
    return error;
  }
};