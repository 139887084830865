import {
  FaChalkboardTeacher,
  FaGraduationCap,
  FaShuttleVan,
} from "react-icons/fa";
import { FaArrowsDownToPeople, FaPeopleGroup } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { GrResources } from "react-icons/gr";
import { IoNotifications } from "react-icons/io5";
import { MdPayments, MdScreenshotMonitor } from "react-icons/md";

import { MdPersonOutline } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { MdOutlineEmergency } from "react-icons/md";
import { PiStudent } from "react-icons/pi";
import { MdOutlineMedicalServices } from "react-icons/md";
import { PiNewspaper } from "react-icons/pi";
import { GrStatusGood } from "react-icons/gr";


export const LEFT_ADMIN_NAVIGATION = [
  { linkName: "Personal", link: "/admissions/personal", icon: <MdPersonOutline /> },
  { linkName: "Parents", link: "/admissions/parents", icon: <BsPeople /> },
  { linkName: "Emergency", link: "/admissions/emergency", icon: <MdOutlineEmergency /> },
  { linkName: "Education", link: "/admissions/education", icon: <PiStudent /> },
  { linkName: "Medical", link: "/admissions/medical", icon: <MdOutlineMedicalServices /> },
  { linkName: "Confirm", link: "/admissions/confirm", icon: <GrStatusGood /> },
  // {
  //   linkName: "Academics",
  //   subNavs: [
  //     { linkName: "Assignments", link: "/academics/assignments" },
  //     { linkName: "Classes", link: "/academics/Classes" },
  //     { linkName: "Courses", link: "/academics/courses" },
  //     { linkName: "Exams & Quizzes", link: "/academics/ExamsQuiz" },
  //     { linkName: "Syllabus", link: "/academics/Syllabus" },
  //     // { linkName: "Academic Year", link: "/AcademicYear/" },
  //     { linkName: "Timetable", link: "/academics/Timetable" },
  //   ],
  //   icon: <FaGraduationCap />,
  // },
];
