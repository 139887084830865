import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
  SelectFieldForId,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allCampus } from "../../../../redux/slice/academics/CampusSlice";
import { IoMdAdd } from "react-icons/io";
import {
  subjectsData,
  sessionsData,
  gradesData,
} from "../../../../db/fields-data";
import Notify from "../../../../components/notify-toast";

export default function CommunityReffernceForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  community,
  forView,
}) {
  let infoObject = "community";
  const { campus } = useSelector(allCampus);
  const [addedNoOfSubjects, setAddedNoOfSubjects] = useState(0);
  const [allowAdd, setAllowAdd] = useState(true);
  const [refferenceDetailsState, setRefferenceDetailsState] = useState(
    community.refferenceDetails || [
      { name: "", relation: "", position: "", campus: "", status: "" },
    ]
  );
  // console.log("data---", refferenceDetailsState);
  useEffect(() => {
    let keysToExclude = ["refferenceDetails"];
    const isAnyKeyEmpty = Object.keys(community)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return community[key] === "" || community[key] === null;
      });
    // console.log("isanyKeyEmpty", isAnyKeyEmpty);
    let emptyReferences = community.refferenceDetails.filter((subject) =>
      Object.values(subject).some((value) => value === "")
    );
    // console.log("emptyReferences", emptyReferences);
    // console.log(
    //   "emptyReferences",
    //   community.refferenceDetails.length - emptyReferences.length == 0
    // );
    if (emptyReferences.length > 0) {
      setAllowAdd(false);
    } else {
      setAllowAdd(true);
    }
    if (!isAnyKeyEmpty) {
      // console.log("isanyKeyEmpty inside");
      if (
        community.isCommunityRef == "Yes" &&
        emptyReferences.length > 0
        // community.refferenceDetails.length - emptyReferences.length == 0
      ) {
        updateTabComplete(false);
      } else {
        updateTabComplete(true);
      }
    } else {
      if (tabs[2].complete) {
        updateTabComplete(false);
      }
    }
  }, [community]);

  const handleAddReffernece = () => {
    if (allowAdd) {
      setRefferenceDetailsState([
        ...refferenceDetailsState,
        { name: "", relation: "", position: "", campus: "", status: "" },
      ]);
      setAllowAdd(false);
    } else {
      Notify("error", "Please Fill the added Rows first");
    }
  };

  const handleRemoveLastReff = () => {
    if (refferenceDetailsState.length > 1) {
      const updatedRefferences = refferenceDetailsState.slice(0, -1);
      setRefferenceDetailsState(updatedRefferences);
      handleChangeInfo(infoObject, "refferenceDetails", updatedRefferences);
    } else {
      Notify("error", "If reference, at least one reference is required");
    }
  };

  const handleDetailChange = (index, key, value) => {
    const updatedRefference = refferenceDetailsState.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setRefferenceDetailsState(updatedRefference);
    handleChangeInfo(infoObject, "refferenceDetails", updatedRefference);
  };

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6 className="mt-4">City School Association</h6>
          <SelectField
            label="Any City School Association"
            compulsory={true}
            value={community.isCommunityRef}
            selectOptions={["No", "Yes"]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "isCommunityRef", e.target.value)
            }
          />
          {community.isCommunityRef == "Yes"
            ? refferenceDetailsState.map((refferPerson, index) => (
                <>
                  <p className="fs-6 text-warning mt-4">
                    Reference Detail {index + 1}
                  </p>
                  <div className="row" key={index}>
                    <InputField
                      label="Name"
                      type="text"
                      compulsory={true}
                      value={refferPerson.name}
                      placeholder="Type Name"
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "name", e.target.value)
                      }
                    />
                    <InputField
                      label="Relation"
                      type="text"
                      compulsory={true}
                      value={refferPerson.relation}
                      placeholder="Type Relation"
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "relation", e.target.value)
                      }
                    />
                    <SelectField
                      label="Position"
                      compulsory={true}
                      value={refferPerson.position}
                      selectOptions={["Staff", "Student"]}
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "position", e.target.value)
                      }
                    />
                    <InputField
                      label="Campus"
                      compulsory={true}
                      value={refferPerson.campus}
                      // selectOptions={campus}
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "campus", e.target.value)
                      }
                    />
                    <SelectField
                      label="Status"
                      compulsory={true}
                      value={refferPerson.status}
                      selectOptions={
                        refferPerson.position === "Student"
                          ? ["Current", "Alumni"]
                          : ["Current", "Former"]
                      }
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "status", e.target.value)
                      }
                    />
                  </div>
                </>
              ))
            : null}
        </div>
        {community.isCommunityRef == "Yes" ? (
          <div>
            <button
              type="button"
              className="btn btn-success my-2"
              onClick={handleAddReffernece}
            >
              Add Reference
            </button>
            <button
              type="button"
              className="btn btn-danger my-2 mx-1"
              onClick={handleRemoveLastReff}
            >
              Remove Last
            </button>
          </div>
        ) : null}
      </form>
    </React.Fragment>
  );
}
