import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import NonAuthLayout from "./components/NonAuthLayout";
// Import scss
import "./assets/scss/theme.scss";
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { authRoutes, userRoutes } from "./routes/allRoutes";
import AppRoute from "./routes/middleware/Authmiddleware";
import Layout from "./components/workingLayout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Activating fake backend
fakeBackend();

const App = (props) => {
  console.log('Domain URL:', window.location.hostname);
  return (
    <React.Fragment>
      <div>
        <ToastContainer />
      </div>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AppRoute>
                <Layout>{route.component}</Layout>
                {/* <Layout>{route.component}</Layout> */}
              </AppRoute>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

// App.propTypes = {
//   layout: PropTypes.any
// }

// const mapStateToProps = (state) => {
//   return {
//     layout: state.Layout,
//   };
// };

// export default connect(mapStateToProps, null)(App);
export default App;
