import React, { useEffect, useState, useContext } from "react";
import { TabContent, TabPane, Modal, Spinner, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allClasses } from "../../../redux/slice/academics/classesSlice";
//Import Breadcrumb
import PersonalInfoForm from "./ApplicantForm/personal-info";
import ParentsInfoForm from "./ApplicantForm/parents-info";
import EmergencyContactForm from "./ApplicantForm/emergency-contact";
import EducationInfoForm from "./ApplicantForm/education-info";
import MedicalInfoForm from "./ApplicantForm/medical-info";
import CurrrentStatusForm from "./ApplicantForm/current-status";
import axios from "axios";
import SideBarContext from "../../../context/sidebar.context";
import { transformDataForAPI } from "./apiUtils";
import { GoIssueClosed } from "react-icons/go";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Step, StepGroup, Stepper } from "@cimpress/react-components";
import { MdPersonOutline } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { MdOutlineEmergency } from "react-icons/md";
import { PiStudent } from "react-icons/pi";
import { MdOutlineMedicalServices } from "react-icons/md";
import { PiNewspaper } from "react-icons/pi";
import { GrStatusGood } from "react-icons/gr";
import { MdErrorOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { SelectField } from "../../../components/input-fields";
import StatupForm from "./startup-form";
import { axiosInstance, instanceAuth } from "../../../api/axiosConfig";
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from "../../../redux/slice/academics/CampusSlice";
import ALevelSubject from "./ApplicantForm/ALevelSubject";
import CommunityReffernceForm from "./ApplicantForm/community-refference";

function calculateAge(dateOfBirth) {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default function NewApplicant(props) {
  const dispatch = useDispatch();
  const { campus, loading, currentCampus } = useSelector(allCampus);
  const { activeStates, setActiveStates, tabs, setTabs } =
    useContext(SideBarContext);
  // for modal display
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: true,
    message: "",
  });
  const [verticalActiveStep, setVerticalActiveStep] = useState(0);
  // Tabs
  const [activeTab, setactiveTab] = useState(1);

  const [campusModal, setCampusModal] = useState(true);

  useEffect(() => {
    console.log("activeStates", activeStates.main);
    if (activeStates.main == "Personal") {
      setactiveTab(1);
    } else if (activeStates.main == "Parents") {
      setactiveTab(2);
    } else if (activeStates.main == "City School Association") {
      setactiveTab(3);
    } else if (activeStates.main == "Emergency") {
      setactiveTab(4);
    } else if (activeStates.main == "Education History") {
      setactiveTab(5);
    } else if (activeStates.main == "A-level Subjects") {
      setactiveTab(6);
    } else if (activeStates.main == "Medical") {
      setactiveTab(7);
    } else if (activeStates.main == "Confirm") {
      setactiveTab(8);
    }
  }, [activeStates]);

  const [createStudentData, setCreateSudentData] = useState({
    personal: {
      studentId: `std#${(Math.random() * 1000).toFixed()}`,
      name: "",
      dateOfBirth: "",
      bForm: "",
      nationality: "",
      gender: "",
      permanentAddress: "",
      residentialAddress: "",
      city: "",
      area: "",
      mobileNumber: "+92",
      emailAddress: "",
      passportSizePicture: [],
      birthCertificate: [],
      b_form: [],
    },
    parents: {
      fatherName: "",
      fatherNICNumber: "",
      fatherOccupation: "",
      fatherOrganization: "",
      fatherEducation: "",
      fatherMobileNumber: "+92",
      fatherEmailAddress: "",
      fatherUploadNIC: [],
      fatherUploadPassportPicture: [],
      motherName: "",
      motherNICNumber: "",
      motherOccupation: "",
      motherOrganization: "",
      motherEducation: "",
      motherMobileNumber: "+92",
      motherEmailAddress: "",
      motherUploadNIC: [],
      motherUploadPassportPicture: [],
      maritalStatus: "",
    },
    community: {
      isCommunityRef: "",
      refferenceDetails: [
        { name: "", relation: "", position: "", campus: "", status: "" },
      ],
    },
    emergency: {
      name: "",
      relWithChild: "",
      mobileNumber: "+92",
      email: "",
      homeAddress: "",
    },
    education: {
      totalNumberOfStudents: "",
      // previouslyAttended: "",
      schoolName: "",
      schoolAddress: "",
      class: "",
      subjectsAppeared: [{ subject: "", session: "", grade: "" }],
      lastAcademicResults: [],
      reasonForLeaving: "",
      term: "",
      campus: "",
    },
    ALevelSubject: {
      subjectsTaken: [{ subject: "" }],
    },
    medical: {
      diabetes: "",
      physicalDisability: "",
      hearingImpairment: "",
      visualImpairment: "",
      anyAllergies: "",
      allergies: "",
    },
    status: {
      status: "",
    },
  });

  const [apiLoader, setApiLoader] = useState(false);

  // const [createStudentData, setCreateSudentData] = useState({
  //   personal: {
  //     studentId: `std#${(Math.random() * 1000).toFixed()}`,
  //     name: "steve",
  //     dateOfBirth: "2005-06-15",
  //     bForm: "1234567891011",
  //     nationality: "Pakistani",
  //     gender: "Male",
  //     permanentAddress: "123 Main Street",
  //     residentialAddress: "456 Elm Street",
  //     city: "Sample City",
  //     area: "Sample Area",
  //     mobileNumber: "+923333332333",
  //     emailAddress: "steve@gmail.com",
  //     passportSizePicture: [],
  //     birthCertificate: [],
  //     b_form: [],
  //   },
  //   parents: {
  //     fatherName: "Michael Doe",
  //     fatherNICNumber: "1234512345671",
  //     fatherOccupation: "Engineer",
  //     fatherOrganization: "ABC Engineering Inc.",
  //     fatherEducation: "Bachelor's Degree",
  //     fatherMobileNumber: "+923333332332",
  //     fatherEmailAddress: "steve12@gmail.com",
  //     fatherUploadNIC: [],
  //     fatherUploadPassportPicture: [],
  //     motherName: "Emily Doe",
  //     motherNICNumber: "1234576543211",
  //     motherOccupation: "Doctor",
  //     motherOrganization: "XYZ Hospital",
  //     motherEducation: "Doctorate Degree",
  //     motherMobileNumber: "+923333332333",
  //     motherEmailAddress: "steve@gmail.com",
  //     motherUploadNIC: [],
  //     motherUploadPassportPicture: [],
  //     maritalStatus: "Married",
  //   },
  //   community: {
  //     isCommunityRef: "",
  //     refferenceDetails: [
  //       {
  //         name: "Williams",
  //         relation: "Elder Brother",
  //         position: "Student",
  //         campus: "4",
  //         status: "Former",
  //       },
  //     ],
  //   },
  //   emergency: {
  //     name: "Jane Smith",
  //     relWithChild: "Aunt",
  //     mobileNumber: "+923333332333",
  //     email: "steve@gmail.com",
  //     homeAddress: "789 Oak Street",
  //   },
  //   education: {
  //     totalNumberOfStudents: 0,
  //     previouslyAttended: "Yes",
  //     schoolName: "Sample School",
  //     schoolAddress: "101 School Avenue",
  //     class: "",
  //     lastAcademicResults: [],
  //     subjectsAppeared: [
  //       { subject: "ACCOUNTING", session: "Oct/Nov 2022", grade: "A" },
  //       { subject: "ARABIC", session: "Oct/Nov 2022", grade: "B" },
  //       { subject: "CHEMISTRY", session: "Oct/Nov 2022", grade: "C" },
  //       { subject: "COMPUTER SCIENCE", session: "Oct/Nov 2022", grade: "D" },
  //       { subject: "ECONOMICS", session: "Oct/Nov 2022", grade: "A" },
  //       { subject: "MATHEMATICS", session: "Oct/Nov 2022", grade: "A" },
  //       { subject: "PHYSICS", session: "Oct/Nov 2022", grade: "B" },
  //       { subject: "URDU", session: "Oct/Nov 2022", grade: "A" },
  //     ],
  //     reasonForLeaving: "Family Relocation",
  //     term: "",
  //     campus: "",
  //   },
  //   ALevelSubject: {
  //     subjectsTaken: [
  //       {
  //         subject: "Further Mathematics",
  //       },
  //     ],
  //   },
  //   medical: {
  //     diabetes: "No",
  //     physicalDisability: "No",
  //     hearingImpairment: "No",
  //     visualImpairment: "No",
  //     anyAllergies: "Yes",
  //     allergies: "Peanuts",
  //   },
  //   status: {
  //     status: "Active",
  //   },
  // });

  const notify = (status, message) => {
    if (status === "success") {
      toast.success(message, {
        icon: <GoIssueClosed size={16} />,
        position: "top-center",
        style: {
          background: "#64D45B",
          color: "white",
          fontSize: "15px",
        },
      });
    } else {
      toast.error(message, {
        icon: <MdErrorOutline size={16} />,
        position: "top-center",
        style: {
          background: "#E67063",
          color: "white",
          fontSize: "15px",
        },
      });
    }
  };

  useEffect(() => {
    console.log("currentCampus in applicant form", currentCampus);
    console.log("currentCampus in applicant form", typeof currentCampus);
  }, [currentCampus]);

  async function sendDataApi() {
    try {
      console.log("createStudentData before sending", createStudentData);
      const formData = new FormData();

      // Append personal data
      formData.append("name", createStudentData.personal.name);
      formData.append("ProspectiveStudentInfo.CampusId", Number(currentCampus));
      formData.append(
        "ProspectiveStudentInfo.ClassId",
        Number(createStudentData.education.class)
      );
      formData.append(
        "age",
        calculateAge(createStudentData.personal.dateOfBirth)
      );
      formData.append("email", createStudentData.personal.emailAddress);
      formData.append("dateOfBirth", createStudentData.personal.dateOfBirth);
      formData.append("gender", createStudentData.personal.gender);
      formData.append("nic", createStudentData.personal.bForm);
      formData.append("nationality", createStudentData.personal.nationality);
      formData.append(
        "permenantAddress",
        createStudentData.personal.permanentAddress
      );
      formData.append(
        "residentialAddress",
        createStudentData.personal.residentialAddress
      );
      formData.append("city", createStudentData.personal.city);
      formData.append("area", createStudentData.personal.area);
      formData.append("phone", createStudentData.personal.mobileNumber);
      formData.append("currentStatus", createStudentData.status.status);
      formData.append(
        "NicImage",
        createStudentData.personal.b_form[
          createStudentData.personal.b_form.length - 1
        ]
      );
      formData.append(
        "StudentImage",
        createStudentData.personal.passportSizePicture[
          createStudentData.personal.passportSizePicture.length - 1
        ]
      );
      formData.append(
        "BirthCertificateImage",
        createStudentData.personal.birthCertificate[
          createStudentData.personal.birthCertificate.length - 1
        ]
      );

      // Append Community References
      if (createStudentData.community.isCommunityRef == "Yes") {
        createStudentData.community.refferenceDetails.forEach((ref, index) => {
          formData.append(`StudentComunityReferences[${index}].name`, ref.name);
          formData.append(
            `StudentComunityReferences[${index}].relation`,
            ref.relation
          );
          formData.append(
            `StudentComunityReferences[${index}].position`,
            ref.position
          );
          formData.append(
            `StudentComunityReferences[${index}].campus`,
            ref.campus
          );
          formData.append(
            `StudentComunityReferences[${index}].status`,
            ref.status
          );
        });
      }

      // Append emergency contact info
      formData.append(
        "studentEmergencyContactInfo.name",
        createStudentData.emergency.name
      );
      formData.append(
        "studentEmergencyContactInfo.email",
        createStudentData.emergency.email
      );
      formData.append(
        "studentEmergencyContactInfo.relationshipType",
        createStudentData.emergency.relWithChild
      );
      formData.append(
        "studentEmergencyContactInfo.phone",
        createStudentData.emergency.mobileNumber
      );
      formData.append(
        "studentEmergencyContactInfo.homeAddress",
        createStudentData.emergency.homeAddress
      );

      // Append education info
      formData.append("ProspectiveStudentInfo.AcademicYearId", 1);
      // formData.append(
      //   "studentEducationInfo.previouslyAttended",
      //   createStudentData.education.previouslyAttended
      // );
      formData.append(
        "studentEducationInfo.schoolName",
        createStudentData.education.schoolName
      );
      formData.append(
        "studentEducationInfo.schoolAddress",
        createStudentData.education.schoolAddress
      );
      formData.append(
        "studentEducationInfo.reasonForLeaving",
        createStudentData.education.reasonForLeaving
      );
      formData.append(
        "studentEducationInfo.LastAcademicResultImage",
        createStudentData?.education?.lastAcademicResults[
          createStudentData?.education?.lastAcademicResults.length - 1
        ]
      );
      formData.append(
        "studentEducationInfo.NoOfStudents",
        Number(createStudentData?.education?.totalNumberOfStudents)
      );
      createStudentData.education.subjectsAppeared.forEach((sub, index) => {
        formData.append(
          `StudentEducationInfo.StudentAcademicProfiles[${index}].subject`,
          sub.subject
        );
        formData.append(
          `StudentEducationInfo.StudentAcademicProfiles[${index}].session`,
          sub.session
        );
        formData.append(
          `StudentEducationInfo.StudentAcademicProfiles[${index}].grade`,
          sub.grade
        );
      });

      // A-level Subject
      createStudentData.ALevelSubject.subjectsTaken.forEach((sub, index) => {
        formData.append(
          `ProspectiveStudentInfo.StdAdmSubInfos[${index}].subject`,
          sub.subject
        );
      });

      // Append medical info
      formData.append(
        "studentMedicalInfo.hasDiabetes",
        createStudentData.medical.diabetes == "Yes" ? true : false
      );
      formData.append(
        "studentMedicalInfo.hasPhysicalDisability",
        createStudentData.medical.physicalDisability == "Yes" ? true : false
      );
      formData.append(
        "studentMedicalInfo.hasHearingImpairment",
        createStudentData.medical.hearingImpairment == "Yes" ? true : false
      );
      formData.append(
        "studentMedicalInfo.hasVisualImpairment",
        createStudentData.medical.visualImpairment == "Yes" ? true : false
      );
      formData.append(
        "studentMedicalInfo.hasAllergy",
        createStudentData.medical.anyAllergies == "Yes" ? true : false
      );
      formData.append(
        "studentMedicalInfo.AllergyReason",
        createStudentData.medical.allergies
      );

      // Append father's data
      formData.append(
        "guardians[0].name",
        createStudentData.parents.fatherName
      );
      formData.append(
        "guardians[0].phone",
        createStudentData.parents.fatherMobileNumber
      );
      formData.append(
        "guardians[0].email",
        createStudentData.parents.fatherEmailAddress
      );
      formData.append(
        "guardians[0].address",
        createStudentData.personal.permanentAddress
      );
      formData.append("guardians[0].gender", "Male");
      formData.append("guardians[0].type", "Father");
      formData.append(
        "guardians[0].occupation",
        createStudentData.parents.fatherOccupation
      );
      formData.append(
        "guardians[0].organization",
        createStudentData.parents.fatherOrganization
      );
      formData.append(
        "guardians[0].Nic",
        createStudentData.parents.fatherNICNumber
      );
      formData.append(
        "guardians[0].maritalStatus",
        createStudentData.parents.maritalStatus
      );
      formData.append(
        "guardians[0].education",
        createStudentData.parents.fatherEducation
      );
      formData.append(
        "guardians[0].nicImage",
        createStudentData.parents.fatherUploadNIC[
          createStudentData.parents.fatherUploadNIC.length - 1
        ]
      );
      formData.append(
        "guardians[0].guardianImage",
        createStudentData.parents.fatherUploadPassportPicture[
          createStudentData.parents.fatherUploadPassportPicture.length - 1
        ]
      );

      // Append mother's data
      formData.append(
        "guardians[1].name",
        createStudentData.parents.motherName
      );
      formData.append(
        "guardians[1].phone",
        createStudentData.parents.motherMobileNumber
      );
      formData.append(
        "guardians[1].email",
        createStudentData.parents.motherEmailAddress
      );
      formData.append(
        "guardians[1].address",
        createStudentData.personal.permanentAddress
      );
      formData.append("guardians[1].gender", "Female");
      formData.append("guardians[1].type", "Mother");
      formData.append(
        "guardians[1].occupation",
        createStudentData.parents.motherOccupation
      );
      formData.append(
        "guardians[1].organization",
        createStudentData.parents.motherOrganization
      );
      formData.append(
        "guardians[1].Nic",
        createStudentData.parents.motherNICNumber
      );
      formData.append(
        "guardians[1].maritalStatus",
        createStudentData.parents.maritalStatus
      );
      formData.append(
        "guardians[1].education",
        createStudentData.parents.motherEducation
      );
      formData.append(
        "guardians[1].nicImage",
        createStudentData.parents.motherUploadNIC[
          createStudentData.parents.motherUploadNIC.length - 1
        ]
      );
      formData.append(
        "guardians[1].guardianImage",
        createStudentData.parents.motherUploadPassportPicture[
          createStudentData.parents.motherUploadPassportPicture.length - 1
        ]
      );
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      const res = await instanceAuth.post(
        "Admission/CreateApplicant",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        }
      );

      console.log("post api response", res);
      if (res?.status === 200) {
        setSubmitted(true);
        setApiLoader(false);
        setErrorMessage({
          status: false,
          message: "",
        });
        notify("success", "Application submited successfully.");
      }
    } catch (err) {
      console.log("err", err);
      setErrorMessage({
        status: true,
        message:
          "Unexpected error encountered. Please try again after few minutes.",
      });
      notify(
        "error",
        "Unexpected error encountered. Please try again after few minutes."
      );
      setApiLoader(false);
    }
  }

  const handleChangeInfo = (parentObjectName, fieldName, value) => {
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: value,
      },
    }));
  };
  const handleFileChange = (parentObjectName, fieldName, event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("file", file);
      console.log("new file", createStudentData[parentObjectName]);
      // const reader = new FileReader();

      // reader.onload = () => {
      // console.log("reader result",reader.result)
      // console.log(reader.result)
      setCreateSudentData((prevState) => ({
        ...prevState,
        [parentObjectName]: {
          ...prevState[parentObjectName],
          [fieldName]: [...prevState[parentObjectName][fieldName], file],
        },
      }));
      // };
      // reader.readAsDataURL(file);
    }
  };
  const handleFileRemove = (parentObjectName, fieldName) => {
    console.log("handle file remove", parentObjectName, "@", fieldName);
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [], // Clear the array for the specified field
      },
    }));
    // const emptyEvent = { target: { files: [] } };
    // handleFileChange(parentObjectName, fieldName, emptyEvent)
  };
  const handleSubmit = async () => {
    setApiLoader(true);
    sendDataApi();
  };

  useEffect(() => {
    if (
      createStudentData.parents.fatherMobileNumber &&
      createStudentData.parents.fatherEmailAddress && 
      createStudentData.parents.fatherNICNumber
    )
      if (
        createStudentData.parents.motherMobileNumber ===
          createStudentData.parents.fatherMobileNumber ||
        createStudentData.parents.fatherEmailAddress ===
          createStudentData.parents.motherEmailAddress ||
        createStudentData.parents.fatherNICNumber ===
          createStudentData.parents.motherNICNumber
      ) {
        setErrorMessage((prev)=>({
          ...prev,
          message: "Father's & Mother's Email Address, Phone Number & CNIC cannot be same"
        }))
        // notify(
        //   "error",
        //   "Father & Mother mobile number and email cannot be same."
        // );
        // setErrorMessage("Father's & Mother's Email Address, Phone Number & CNIC cannot be same");
      }
      else{
        setErrorMessage((prev)=>({
          ...prev,
          status: true,
          message: ""
        }))
      }
  }, [
    createStudentData.parents.fatherMobileNumber,
    createStudentData.parents.fatherEmailAddress,
    createStudentData.parents.motherMobileNumber,
    createStudentData.parents.motherEmailAddress,
    createStudentData.parents.fatherNICNumber,
    createStudentData.parents.motherNICNumber,
  ]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 8) {
        setactiveTab(tab);
        setActiveStates({
          main: tabs[tab - 1].tab,
          child: "",
        });
        setVerticalActiveStep(tab - 1);
      }
    }
  }
  const updateTabComplete = (boolVal) => {
    // console.log("boolVal", boolVal);
    const updated_tabs = [...tabs];
    updated_tabs[activeTab - 1].complete = boolVal;
    setTabs(updated_tabs);
    if (
      tabs[0].complete == true &&
      tabs[1].complete == true &&
      tabs[2].complete == true &&
      tabs[3].complete == true &&
      tabs[4].complete == true &&
      tabs[5].complete == true &&
      tabs[6].complete == true
      // && tabs[5].complete == true
    ) {
      updated_tabs[7].complete = true;
    } else {
      updated_tabs[7].complete = false;
    }
    // console.log("tabs", tabs);
  };
  const checkmarkIcon = "✓";
  let stepsItems = [
    { name: "Personal", icon: <MdPersonOutline /> },
    { name: "Parents", icon: <BsPeople /> },
    { name: "Emergency", icon: <MdOutlineEmergency /> },
    { name: "Education", icon: <PiStudent /> },
    { name: "Medical", icon: <MdOutlineMedicalServices /> },
    { name: "Confirm", icon: <GrStatusGood /> },
  ];
  function ModalToggle() {
    setCampusModal(!campusModal);
  }
  const handleModalSubmit = () => {
    if (
      createStudentData.education.campus &&
      createStudentData.education.class &&
      createStudentData.education.term
    ) {
      setCampusModal(false);
    } else {
      notify("error", "Please Fill all the fields");
    }
  };
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div
            className="card"
            style={{ margin: "2% 6% 2% 6%", zIndex: "100" }}
          >
            <Modal
              size="lg"
              isOpen={campusModal}
              // toggle={() => ModalToggle()}
              centered
            >
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title fs-5 text-center mb-4 poppins-bold">
                    Select campus and other details
                  </h3>
                  <StatupForm
                    tabs={tabs}
                    forUpdate={true}
                    updateTabComplete={updateTabComplete}
                    educationInfo={createStudentData.education}
                    handleFileChange={handleFileChange}
                    handleChangeInfo={handleChangeInfo}
                    handleFileRemove={handleFileRemove}
                    forView={false}
                  />
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary mt-4 w-100 "
                      disabled={
                        !createStudentData.education.campus ||
                        !createStudentData.education.class ||
                        !createStudentData.education.term
                      }
                      onClick={handleModalSubmit}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80px", width: "102%", marginLeft: "-1%", backgroundColor: "blue", borderRadius: "4px" }}
            >
              <h4
                className="text-center poppins-semibold d-flex justify-content-center align-items-center"
                style={{ height: "80px", width: "100%", borderRadius: "5px", color: "blue" }}
              >
                Admission Form 2024-25
              </h4>
            </div> */}
            <div className="card-body">
              <p
                className="text-center admission-heading"
                style={{ fontSize: "100px !important" }}
              >
                THE CITY SCHOOL
              </p>
              <p
                className="text-center admission-heading-session mb-4"
                style={{ fontSize: "50px !important" }}
              >
                REGISTRATION FOR ACADEMIC SESSION 2024 - 2025
              </p>
              {/* <button onClick={()=>{notify("error", "Form Submitted Successfully.")}}>Test Toast</button> */}
              <div className="stepper-wrapper d-sm-none d-md-block mobile-none">
                <Stepper className="w-100" activeStep={verticalActiveStep}>
                  {tabs.map((step, index) => (
                    <Step
                      className="stepper-step"
                      key={index}
                      icon={step.icon}
                      onClick={
                        step.complete ? () => setactiveTab(index + 1) : null
                      }
                      disabled={!step.complete}
                    >
                      <div
                        className={`point ${
                          activeTab === step.no
                            ? "text-black"
                            : "text-secondary"
                        } ?`}
                        // onClick={step.complete ? () => setactiveTab(index + 1) : null}
                        // onClick={()=>setactiveTab(index + 1)}
                      >
                        {step.tab}
                      </div>
                    </Step>
                  ))}
                </Stepper>
              </div>
              {/* <div className="progress" style={{height: "25px"}} role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success poppins-bold fs-5" style={{width: "75%"}}>75%</div>
              </div> */}
              <div className="form-wizard-wrapper wizard clearfix">
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <PersonalInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        personalInfo={createStudentData.personal}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <ParentsInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        parentsInfo={createStudentData.parents}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <CommunityReffernceForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        community={createStudentData.community}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    <TabPane tabId={4}>
                      <EmergencyContactForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        emergencyContact={createStudentData.emergency}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    <TabPane tabId={5}>
                      <EducationInfoForm
                        tabs={tabs}
                        forUpdate={true}
                        updateTabComplete={updateTabComplete}
                        educationInfo={createStudentData.education}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    <TabPane tabId={6}>
                      <ALevelSubject
                        tabs={tabs}
                        forUpdate={true}
                        updateTabComplete={updateTabComplete}
                        AlevelSubjectInfo={createStudentData.ALevelSubject}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                      {/* <EducationInfoForm
                        tabs={tabs}
                        forUpdate={true}
                        updateTabComplete={updateTabComplete}
                        educationInfo={createStudentData.education}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      /> */}
                    </TabPane>
                    <TabPane tabId={7}>
                      <MedicalInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        medicalInfo={createStudentData.medical}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={false}
                      />
                    </TabPane>
                    {/* <TabPane tabId={6}>
                      <CurrrentStatusForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        currentStatus={createStudentData.status}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        forView={false}
                      />
                    </TabPane> */}
                    <TabPane tabId={8}>
                      <div className="row justify-content-center">
                        <div className="col-sm-6">
                          <div className="text-center">
                            <div className="mb-4">
                              {tabs[5].complete == true ? (
                                apiLoader ? (
                                  <div
                                    className="spinner-border text-primary"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      marginRight: "4px",
                                    }}
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <GoIssueClosed size={50} color="green" />
                                )
                              ) : (
                                <IoCloseCircleOutline size={50} color="red" />
                              )}
                            </div>
                            <div className="mb-5">
                              {tabs[5].complete == true ? (
                                <>
                                  {submitted ? (
                                    <h5>Form Submitted</h5>
                                  ) : (
                                    <>
                                      <h5>Confirm Detail</h5>
                                      <p className="text-muted fs-5">
                                        Please click submit in order to proceed
                                        with your application. Kindly ensure
                                        that all information provided is
                                        accurate and concise.
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <h5>Some Fields Missing</h5>
                                  <p className="text-muted">
                                    You need to fill all the fields with{" "}
                                    <span className="text-danger">* </span>sign
                                  </p>
                                </>
                              )}
                              {/* <p
                                className={`${
                                  errorMessage.status == true
                                    ? "bg-danger text-danger-emphasis"
                                    : "bg-success text-success-emphasis"
                                } poppins-bold rounded fs-5`}
                              >
                                {errorMessage.message}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <p className="text-danger m-2 fs-6 float-start text-center poppins-medium">{errorMessage.message}</p>  
                  {!submitted ? (
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      {activeTab == 8 ? (
                        <li className={activeTab === 8 ? "" : "next"}>
                          <button
                            to="#"
                            // type="submit"
                            className="btn btn-success"
                            disabled={
                              !tabs[activeTab - 1]?.complete || apiLoader
                            }
                            onClick={handleSubmit}
                          >
                            {/* <div class="spinner-border text-primary" style={{width: "15px", height: "15px", marginRight:"4px"}} role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div> */}
                            {apiLoader ? "Submitting.." : "Submit"}
                          </button>
                        </li>
                      ) : (
                        <li className={activeTab === 8 ? "" : "next"}>
                          <button
                            to="#"
                            className="btn btn-primary"
                            disabled={!tabs[activeTab - 1]?.complete}
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </button>
                        </li>
                      )}
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
