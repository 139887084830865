import { axiosInstance, instanceAuth } from '../axiosConfig';

export const fetchCampusData = async () => {
  try {
    const response = await instanceAuth.get('Campus');
    return response.data;
  } catch (error) {
    console.log('error-fetching-data', error);
    throw error;
  }
};