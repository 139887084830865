import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
  InputNumber,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allClasses } from "../../../../redux/slice/academics/classesSlice";
import { IoMdAdd } from "react-icons/io";
import {
  subjectsData,
  sessionsData,
  gradesData,
} from "../../../../db/fields-data";
import Notify from "../../../../components/notify-toast";

export default function EducationInfoForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  educationInfo,
  forView,
}) {
  let infoObject = "education";
  const { classes } = useSelector(allClasses);
  const classNames = [...new Set(classes.map((cls) => cls.name))];
  const [addedNoOfSubjects, setAddedNoOfSubjects] = useState(0);
  const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState(
    educationInfo.subjectsAppeared || [{ subject: "", session: "", grade: "" }]
  );
  const [allowAdd, setAllowAdd] = useState(true);

  useEffect(() => {
    let keysToExclude = ["class"];
    const isAnyKeyEmpty = Object.keys(educationInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return educationInfo[key] === "" || educationInfo[key] === null;
      });
    // console.log(
    //   "educationInfo.subjectsAppeared",
    //   educationInfo.subjectsAppeared
    // );
    let emptySubjects = educationInfo.subjectsAppeared.filter((subject) =>
      Object.values(subject).some((value) => value === "")
    );
    if (emptySubjects.length > 0) {
      setAllowAdd(false);
    } else {
      setAllowAdd(true);
    }
    // console.log(
    //   "conditions",
    //   !isAnyKeyEmpty,
    //   emptySubjects.length == 0,
    //   educationInfo.subjectsAppeared.length - emptySubjects.length >= 8
    // );
    if (
      !isAnyKeyEmpty &&
      emptySubjects.length == 0 &&
      educationInfo.subjectsAppeared.length - emptySubjects.length >= 8
    ) {
      updateTabComplete(true);
    } else {
      if (tabs[4].complete) {
        updateTabComplete(false);
      }
    }
  }, [educationInfo]);

  const handleAddSubject = () => {
    if (allowAdd) {
      setSubjectsAppearedDetails([
        ...subjectsAppearedDetails,
        { subject: "", session: "", grade: "" },
      ]);
      setAllowAdd(false);
    } else {
      Notify("error", "Please Fill the previous Row first");
    }
  };

  const handleRemoveLastSubject = () => {
    if (subjectsAppearedDetails.length > 1) {
      const updatedSubjects = subjectsAppearedDetails.slice(0, -1);
      setSubjectsAppearedDetails(updatedSubjects);
      handleChangeInfo(infoObject, "subjectsAppeared", updatedSubjects);
    } else {
      Notify("error", "At least one subject is required");
    }
  };

  // useEffect(() => {
  //   const isSubjectsEmpty = subjectsAppearedDetails.some((subject) =>
  //     Object.values(subject).some((value) => value === '')
  //   );
  //   let emptySubjects = educationInfo.subjectsAppeared.filter((subject) =>
  //     Object.values(subject).some((value) => value === '')
  //   );
  // }, [subjectsAppearedDetails, educationInfo]);

  const handleSubjectChange = (index, key, value) => {
    const updatedSubjects = subjectsAppearedDetails.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setSubjectsAppearedDetails(updatedSubjects);
    handleChangeInfo(infoObject, "subjectsAppeared", updatedSubjects);
  };

  const getFilteredSubjects = (index) => {
    const selectedSubjects = subjectsAppearedDetails
      .filter((subject, i) => i !== index)
      .map((subject) => subject.subject);
    return subjectsData.filter(
      (subject) => !selectedSubjects.includes(subject)
    );
  };

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6>Education Information</h6>
          {/* Other fields */}
          <h6 className="mt-4">Previously Attended</h6>
          <InputField
            label="School Name"
            type="text"
            compulsory={true}
            value={educationInfo.schoolName}
            placeholder="School Name"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "schoolName", e.target.value)
            }
          />
          <InputField
            label="School Address"
            type="text"
            compulsory={true}
            value={educationInfo.schoolAddress}
            placeholder="School Address"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "schoolAddress", e.target.value)
            }
          />
          <InputNumber
            label="Total No. of Students"
            compulsory={true}
            value={educationInfo.totalNumberOfStudents}
            step={1}
            min={0}
            max={200}
            placeholder="Enter Total No. of Students"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "totalNumberOfStudents",
                e.target.value
              )
            }
          />
          <InputImageField
            label="Last Academic Results"
            type="file"
            compulsory={false}
            value={educationInfo.lastAcademicResults}
            placeholder="Upload Last Academic Results"
            disabled={forView}
            handleFileRemove={() =>
              handleFileRemove(infoObject, "lastAcademicResults")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "lastAcademicResults", e)
            }
          />
          <InputField
            label="Reason for Leaving"
            type="text"
            compulsory={true}
            value={educationInfo.reasonForLeaving}
            placeholder="Reason for Leaving"
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "reasonForLeaving", e.target.value)
            }
          />
          <h6 className="mt-4">Academic Profile</h6>
          <ol className="mx-2">
            <li>
              Please select the subjects you have taken or will be taking final
              CAIE (or equivalent) examinations for.
            </li>
            <li>
              For each of the subjects, please add the relevant school grades.
            </li>
            <li>
              If you have already taken the examination, please add the CAIE
              grade, otherwise, please indicate the grade as "Pending".
            </li>
          </ol>
          <p
            className={`poppins-semibold ${
              subjectsAppearedDetails.length > 7
                ? "text-success"
                : "text-danger"
            }`}
          >
            Provided Subjects: {educationInfo?.subjectsAppeared.length}{" "}
            <span className="">{"(Add at least 8 subjects)"}</span>
          </p>
          {subjectsAppearedDetails.map((subject, index) => (
            <div className="row" key={index}>
              <SelectField
                label={`Subject ${index + 1}`}
                compulsory={true}
                value={subject.subject}
                selectOptions={getFilteredSubjects(index)}
                disabled={forView}
                onChange={(e) =>
                  handleSubjectChange(index, "subject", e.target.value)
                }
              />
              <SelectField
                label="Session"
                compulsory={true}
                value={subject.session}
                selectOptions={sessionsData}
                disabled={forView}
                onChange={(e) =>
                  handleSubjectChange(index, "session", e.target.value)
                }
              />
              <SelectField
                label="CAIE Grade"
                compulsory={true}
                value={subject.grade}
                selectOptions={gradesData}
                disabled={forView}
                onChange={(e) =>
                  handleSubjectChange(index, "grade", e.target.value)
                }
              />
            </div>
          ))}
        </div>
        <div className="my-2">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddSubject}
          >
            Add Subject
          </button>
          {subjectsAppearedDetails.length > 8 ? (
            <button
              type="button"
              className="btn btn-danger mx-1"
              onClick={handleRemoveLastSubject}
            >
              Remove Last
            </button>
          ) : null}
        </div>
      </form>
    </React.Fragment>
  );
}
