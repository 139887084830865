import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/logo/city-logo-2.png";
import { GoogleLogin } from "@react-oauth/google";
import {
  LoginUser,
  LoginWithGoogle,
  allLogin,
} from "../../redux/slice/authentication/loginSlice";
import Notify from "../../components/notify-toast";

const Login = (props) => {
  const dispatch = useDispatch();
  const { response, error, loading } = useSelector(allLogin);

  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const disabled = !loginData.email || !loginData.password;

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleSubmit = () => {
    if (!emailRegex.test(loginData.email)) {
      Notify(
        "error",
        "Invalid Email"
      );  
    }
    else{
      dispatch(
        LoginUser({
          username: loginData.email,
          password: loginData.password,
        })
      );
    }
  };

  useEffect(() => {
    if (response?.status === 200) {
      setErrorMsg("");
      navigate("/");
    }
    else if(response?.response?.data[0]){
      Notify(
        "error",
        response?.response?.data[0]?.description
      );
    }
    else if(response?.status != 200 && response?.data?.message){
      Notify(
        "error",
        response?.data?.message
      );
    }
    else if(response?.status === 400){
      Notify(
        "error",
        response?.data
      );   
    }
    else if(response === "ERR_NETWORK"){
      Notify(
        "error",
        "Something went wrong Check Network Connectivity!!"
      );
    }
  }, [response]);

  // google login work
  const googleLoginSuccess = async (response) => {
    // console.log("Response", response);
    dispatch(
      LoginWithGoogle(response.credential)
    );
  };

  const googleLoginFailure = (error) => {
    console.log(error);
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <h2>
            <i className="fas fa-home" />
          </h2>
        </Link>
      </div> */}
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50 mb-0">
                      Sign in to continue to City School.
                    </p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="52" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                      <div style={{ display: "flex", flexDirection: "column !important", flexGrow: "1", justifyContent: "center", alignItems: "center", margin: "auto", background: "transparent", height: "80px"}}>
                        <GoogleLogin onSuccess={googleLoginSuccess} onError={googleLoginFailure} theme="filled_black" width={300} text="continue_with" />
                        {/* <h4>login With GOogle</h4> */}
                      </div>
                    </div>
                    {errorMsg ? <p className="text-center text-danger">{errorMsg}</p> : null}
                    <p className="text-center mt-4">OR</p>
                    {/* {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null} */}
                    <div className="mb-3">
                      <label>Enter Email</label>
                      <input
                        value={loginData.email}
                        className="form-control"
                        placeholder="Enter email"
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            email: e.target.value,
                          })
                        }
                        type="email"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label>Enter Password</label>
                      <input
                        className="form-control"
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            password: e.target.value,
                          })
                        }
                        value={loginData.password}
                        type="password"
                        required
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit();
                          }
                        }}
                        placeholder="Enter Password"
                      />
                    </div>
                    {/* {response?.status === 400 ? (
                      <p className="text-danger mt-2">{response?.data}</p>
                    ) : (
                      ""
                    )}
                    {response === "ERR_NETWORK" ? (
                      <p className="text-danger mt-2 ">
                        Something went wrong Check Network Connectivity!!
                      </p>
                    ) : (
                      ""
                    )} */}
                    <div className="mt-3">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        disabled={disabled}
                        onClick={() => handleSubmit()}
                      >
                        {
                          loading ? 
                          <div className="spinner-border text-light" style={{height: "16px", width: "16px"}} role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div> :
                          "Log In"
                        }
                      </button>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1"></i> Forgot your
                        password?
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center text-white">
                <p>
                  Don't have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} HappyDigital.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {
//   const { error } = state.Login;
//   return { error };
// };

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
// );

export default Login;

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
//   socialLogin: PropTypes.func,
// };
