import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchClassData,
} from '../../../api/Class/ClassApiHelper';

export const ClassesData = createAsyncThunk(
  'class/FetchClassData',
  async (data) => {
    try {
      const response = await fetchClassData(data);
      return response;
    } catch (error) {
      throw Error('Failed to fetch classes data');
    }
  }
);

const initialState = {
  classes: [],
  loading: false,
  error: null,
};

export const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    addClass: (state, action) => {
      console.log('adding class', action.payload);
      state.classes.push(action.payload);
    },
    updateClass: (state, action) => {
      console.log('updating class', action.payload);
    },
    removeClass: (state, action) => {
      // console.log(action.payload)
      return {
        ...state,
        classes: state.classes.filter((item) => action.payload !== item.name),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ClassesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(ClassesData.fulfilled, (state, action) => {
        console.log('class data payload!', action.payload);
        state.loading = false;
        state.classes = action.payload.items;
      })
      .addCase(ClassesData.rejected, (state, action) => {
        state.loading = false;
        console.log("error", action.error.message)
        state.classes= []
        state.error = action.error.message;
      });
  },
});

export const { addClass, removeClass, updateClass } = classSlice.actions;
export const allClasses = (state) => state.allClasses;
export default classSlice.reducer;
