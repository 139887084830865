// import PropTypes from 'prop-types'
// import React, { useEffect } from "react"
// import { connect } from "react-redux"

// import { logoutUser } from "../../store/actions"
// import withRouter from '../../components/Common/withRouter'

// const Logout = props => {
//   useEffect(() => {
//     props.logoutUser(props.history)
//   })

//   return <></>
// }

// Logout.propTypes = {
//   history: PropTypes.object,
//   logoutUser: PropTypes.func
// }

// export default withRouter(connect(null, { logoutUser })(Logout))

import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { logoutUser } from "../../store/actions";
import withRouter from "../../components/Common/withRouter";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);
  return (
    <React.Fragment>
      <h1>LOGING OUT</h1>
    </React.Fragment>
  );
}

export default Logout;

// export default withRouter(connect(null, { logoutUser })(Logout));
