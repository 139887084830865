import React from "react";
// Dashboard
import Dashboard from "../pages/Dashboard";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// School MS
// import Assigments from "../pages/Academics/Assignments";
// import Courses from "../pages/Academics/Courses/Courses";
// import AcademicYear from "../pages/Academics/AcademicYear";
// import Syllabus from "../pages/Academics/Syllabus";
// import TeacherCatalog from "../pages/Teachers/Catalog";
// import Classes from "../pages/Academics/Classes";
// import ExamsQuiz from "../pages/Academics/ExamQuiz";
// import CreateCourse from "../pages/Academics/CreateCourse/CreateCourse";
// import TeacherFeedback from "../pages/Teachers/Feedback";
// import Alumni from "../pages/Students/Alumni";
// import StudentCatalogue from "../pages/Students/Academics";
// import TimeTable from "../pages/Academics/TimeTable/TimeTable";
// import Finance from "../pages/Finance/Fees/index.jsx";
// import InvoiceSearch from "../pages/Finance/Invoice/index.jsx";
// import Attendance from "../pages/Staff/Attendance";
// import UpdateStaffUi from "../pages/Staff/Catalogue/updateStaff.js";
// import ViewStaffUi from "../pages/Staff/Catalogue/viewStaff";
// import CreateStaffUi from "../pages/Staff/Catalogue/createStaff.js";
// import UpdateClass from "../pages/Academics/Classes/update-class"
// import Catalogue from "../pages/Staff/Catalogue/index";
// import Leave from "../pages/Staff/Leave/index";
// import CreateLeaveUi from "../pages/Staff/Leave/createLeave.js";
// import StudentAttendance from "../pages/Students/Attendance/index.jsx";
// import AdmissionApplicants from "../pages/Admissions/Applicants/index.jsx";

// Route for Admission Route
import AdmissionForm from "../pages/Admissions/Applicants/index.jsx";

const userRoutes = [
  //! School MS
  //Academics
  // { path: "/academics/assignments", component: <Assigments /> },
  // { path: "/academics/classes", component: <Classes /> },
  // { path: "/academics/Syllabus", component: <Syllabus /> },
  // { path: "/academics/academicYear", component: <AcademicYear /> },
  // { path: "/academics/courses", component: <Courses /> },
  // { path: "/academics/ExamsQuiz", component: <ExamsQuiz /> },
  // { path: "/academics/timetable", component: <TimeTable /> },
  // { path: "/academics/courses/createcourse", component: <CreateCourse /> },

  //Teacher
  // { path: "/Teachers/Catalog", component: <TeacherCatalog /> },
  // { path: "/Teachers/Feedback", component: <TeacherFeedback /> },

  //Student
  // { path: "/Students/Alumni", component: <Alumni /> },
  // { path: "/Students/Catalogue", component: <StudentCatalogue /> },
  // { path: "/Students/Attendance", component: <StudentAttendance /> },

  // Staff
  // { path: "/staff/Catalog", component: <Catalogue /> },
  // { path: "/staff/viewStaff", component: <ViewStaffUi /> },
  // { path: "/staff/updateStaff", component: <UpdateStaffUi /> },
  // { path: "/staff/createStaff", component: <CreateStaffUi /> },
  // { path: "/staff/leave", component: <Leave /> },
  // { path: "/staff/createleave", component: <CreateLeaveUi /> },
  // { path: "/staff/attendance", component: <Attendance /> },

  //Finance
  // { path: "/Finance/Fees", component: <Finance /> },
  // { path: "/Finance/Invoices", component: <InvoiceSearch /> },

  // Admissions
  // { path: "/Applicants/Applicants", component: <AdmissionApplicants /> },

  //! School MS end

  // { path: "/dashboard", component: <Dashboard /> },
  // For Admission
  { path: "/", exact: true, component: <AdmissionForm /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { userRoutes, authRoutes };
