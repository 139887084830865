import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from "../../redux/slice/academics/CampusSlice";

export default function CampusDropdown() {
  const dispatch = useDispatch();
  const [campusOptions, setCampusOptions] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(0);
  const { campus, loading, currentCampus } = useSelector(allCampus);

  useEffect(() => {
    console.log("campuses availaible in dropdown", campus);
    if(campus){
      const transformedCampusData = campus.map((item) => ({
        id: item?.id,
        name: item?.name,
      }));
      setCampusOptions(transformedCampusData);
    }
  }, [campus]);

  useEffect(() => {
    if (currentCampus) {
      setSelectedCampus(currentCampus);
    }
  }, [currentCampus]);

  useEffect(() => {
    console.log("Selected Campus:", selectedCampus);
  }, [selectedCampus]);


  const handleChange = (e) => {
    console.log("selected campus", e);
    setSelectedCampus(e);
    dispatch(currentCampusSelect(e));
  };

  return (
    <div className="dropdown">
      <span className="dropdown-title">
        Select Campus <FaChevronDown />
      </span>
      <div className="dropdown-content">
        {campusOptions.map((campusData) => (
          <button
            key={campusData.id}
            className={`dropdown-item ${
              selectedCampus == campusData.id ? "selected" : ""
            }`}
            onClick={() => handleChange(campusData.id)}
          >
            {campusData.name}
          </button>
        ))}
      </div>
    </div>
  );
}
