import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchTermData,
} from '../../../api/Term/termApiHelper';

export const AcademicTermData = createAsyncThunk(
  'Term/AcademicTermData',
  async () => {
    try {
      const response = await fetchTermData();
      return response; 
    } catch (error) {
      throw Error('Failed to fetch academic year');
    }
  }
);

const initialState = {
  academicYear: [],
  loading: false,
  error: null,
};

export const AcademicTerm = createSlice({
  name: 'term',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AcademicTermData.pending, (state) => {
        state.loading = true;
      })
      .addCase(AcademicTermData.fulfilled, (state, action) => {
        state.loading = false;
        console.log("academics year in slice", action.payload)
        state.academicYear = action.payload;
      })
      .addCase(AcademicTermData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export const allTerms = (state) => state.allTerms;

export default AcademicTerm.reducer;
