import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <div id="loader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
